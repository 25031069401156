import React from 'react'
import { policyLinks } from 'constant/links'

import SubLinks from 'components/Shared/SubLinks'

const ShopNav = ({ location }) => (
  <SubLinks links={policyLinks} location={location} />
)

export default ShopNav
