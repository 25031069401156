import React from 'react';
import { graphql } from 'gatsby';
// import { MDXRenderer } from 'gatsby-plugin-mdx';

import Seo from 'components/SEO';
import PageContent from 'components/Shared/_PageContent';
import Title from 'components/Shared/Title';
import { ButtonExtLink } from 'components/Shared/_Links';

import PolicyNav from 'components/member/PolicyNav';
import grade9_12 from 'assets/files/member/230831_高三國三團員政策.pdf';

import styled from 'styled-components';
const Wrapper = styled.dl`
  --border-radius: 0.2rem;
  width: clamp(18ch, 75ch, 100%);
  min-height: 800px;
  margin-bottom: calc(var(--margin-default) * 2.5);
  h3 {
    margin-top: var(--margin-default);
  }
  p {
    --text-align: left;
    &:last-child {
      --margin-bottom: var(--margin-default);
    }
  }
  a {
    margin-bottom: var(--margin-default);
  }
  .alert {
    background-color: var(--color-alert);
    color: var(--color-white);
    padding: 0.2rem 0.5rem;
  }
  li {
    padding-left: 1rem;

    &:before {
      content: '*';
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.8;
    }
  }
  .desc {
    --text-align: center;
    &:first-of-type {
      --margin-bottom: 0;
    }
  }
  .file {
    margin-top: var(--margin-default);
    margin-bottom: calc(var(--margin-default) * 1.5);
    &:first-of-type {
      margin-right: 2rem;
    }
  }
`;

const Policy = ({ data, location }) => {
  // const {
  //   grade9_12: { body },
  //   seo,
  // } = data
  const { seo } = data;
  const { title, description } = seo.frontmatter.policy_spec;
  return (
    <PageContent>
      <Seo title={title} description={description} />
      <PolicyNav location={location} />
      <Title>國三、高三團員政策</Title>
      <Wrapper>
        <p className='desc'>2023.08.31 </p>
        <ButtonExtLink href={grade9_12} target='_blank' download>
          「國三、高三團員政策」詳細說明下載
        </ButtonExtLink>
        {/* <MDXRenderer>{body}</MDXRenderer> */}
      </Wrapper>
    </PageContent>
  );
};

export default Policy;

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        policy_spec {
          title
          description
        }
      }
    }
  }
`;
// export const query = graphql`
//   {
//     seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
//       frontmatter {
//         policy_spec {
//           title
//           description
//         }
//       }
//     }

//     grade9_12: mdx(frontmatter: { slug: { eq: "grade9_12" } }) {
//       body
//     }
//   }
// `
